import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import { Star, MedicalServices, HomeRepairService, Build } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import { animateScroll as scroll } from "react-scroll";


const useStyles = makeStyles()((theme) => ({
	heroContent: {
		padding: theme.spacing(8, 0, 6),
	},
	cardHeader: {
		backgroundColor: 'white',
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
	}
}));


const tiers = [
  {
    title: 'Parts and Kits',
    price: '0',
    description: 'We also sell original, and recycled parts and consumables for most laser printers. Our technicians will help you find the part that is right for you at a price that makes the repair worth while.',
    icon: <HomeRepairService style={{width: "100%", height: 70, padding: 12}}  />
  },
  {
		title: 'On Site Service',
		price: '65',
		description: 'Most service calls are less than $65.00 including travel and labor. Our technicians specialize in rapid on site service and can typically make same day calls.',
    icon: <MedicalServices style={{width: "100%", height: 70, padding: 12}} />
	},
	{
		title: 'Tune Ups',
		price: '25',
		description: 'A printer cleaning and tune up starts at only $25.00, for 3 or more printers.',
    icon: <Build style={{width: "100%", height: 70, padding: 12}} />

	}
];

export default function ServicesPage() {
	const { classes } = useStyles();
	return (
		<Container maxWidth="md" id="services">
      {/* Hero unit */}
      <Container className={classes.heroContent}>
        <Typography component="h3" variant="h4" align="left" color="textPrimary" gutterBottom>
          Repair and Maintenance
        </Typography>
        <Typography component="p" variant="h6" align="left" color="textSecondary">
          We service all HP, OKI, Lexmark and Canon printers and fax machines.
        </Typography>
        <Typography component="p" align="left" color="textPrimary" gutterBottom>
          We also offer low cost preventative care service agreements that will save you a great deal of printer down time and expense
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="center" justifyContent={"center"}>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} sm={12} md={4}>
              <Card style={{backgroundColor: 'whitesmoke', height: 410}}>
                <Grid container justifyItems="center">
                  <Grid item xs={12} justifyItems="center" alignItems={"center"} alignContent={'center'} justifyContent={'center'}>
                    {tier.icon}
                  </Grid>
                  
                  <Grid item xs={12}>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={tier.title === 'Printer Repair' ? <Star /> : null}
                      className={classes.cardHeader}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CardContent>
                      {tier.price !== '0' ? <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h3" color="textPrimary">
                          ${tier.price}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          /per
                        </Typography>
                      </div> : <div />}
                      <Typography component="p" variant="subtitle1" align="center">
                        {tier.description}
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
                
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <Button fullWidth variant={'outlined'} color="primary" onClick={scroll.scrollToBottom}>
              Find Out More
            </Button>
          </Grid>

        </Grid>
      </Container>
    </Container>
	);
}
