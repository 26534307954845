import React from "react";

import {
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

export default function Map() {
  const MyMapComponent = withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 43.03130349673291, lng: -89.39457136716133 }}
    >
      {props.isMarkerShown && <Marker position={{ lat: 43.03130349673291, lng: -89.39457136716133 }} />}
    </GoogleMap>
  );
    return (
      <MyMapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
}
