import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import PhoneIcon from '@mui/icons-material/Call';
import madison from "./madison.jpg";
import TonerPage from './TonerPage';
import ServicesPage from './ServicesPage';
import ContactPage from './ContactPage';



const useStyles = makeStyles()((theme) => ({
	toolbar: {
		flexWrap: 'wrap',
	},
	logo: {
		flexGrow: 1,
	},
	link: {
		margin: theme.spacing(1, 1.5),
		fontSize: '1.2rem'
	},
	heroContent: {
		padding: theme.spacing(8, 0, 6),
	},
	cardHeader: {
    color: 'black',
		backgroundColor: theme.palette.grey[200],
	},
	nav: {
		alignSelf: 'flex-end'
	},
	footer: {
		borderTop: `1px solid ${theme.palette.divider}`,
		marginTop: theme.spacing(8),
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	},

	mainFeaturedPost: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		backgroundImage: 'madison.jpg',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	overlay: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		backgroundColor: 'rgba(0,0,0, .4)',
	},
	mainFeaturedPostContent: {
		position: 'relative',
		padding: theme.spacing(3)
	},
  phone: {
    fontSize: '2em',
    color: 'black',
    textDecoration: 'none'
  },
  email: {
    fontSize: '1.4em',
    color: 'black',
    textDecoration: 'none'
  }
}));


export default function MainPage() {
	const { classes } = useStyles();

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
  });

	return (
  	<div className="app min-h-screen text-blue-200 flex items-center flex-col p-20">
      <Paper className={classes.mainFeaturedPost}  style={{ backgroundImage: `url(${madison})` }}>
        {/* Increase the priority of the hero background image */}
        {<img style={{ display: 'none' }} src={madison} alt="MADISON" />}
        <div className={classes.overlay} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                Serving Southern Wisconsin Since 1972
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                Micron Business Products is a family owned and operated business that has been selling and servicing printers for over 50 years.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Card>
                <CardHeader
                  title="Schedule Service"
                  subheader="Or get a free quote"
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <Typography component="p" variant="subtitle1" align="center">
                    <a className={classes.phone} href="tel:+16082742099">(608) 274-2099</a>
                  </Typography>
                  <Typography component="p" variant="subtitle1" align="center">
                    <a className={classes.email} href={"mailto:gpalmer@micronbp.com"}>gpalmer@micronbp.com</a>
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <ServicesPage />
      <br />
      <br />
      <TonerPage />
      <ContactPage />
      {/* <AboutPage /> */}
      {/* <br />
      <br /> */}
    </div>
  );
}
