import React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import logo from "./logo.png";
import MainPage from './MainPage';


const useStyles = makeStyles()((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  noPad: {
    padding: 0
  },
  link: {
    color: 'black',
    textDecoration: 'none'
  },
}));

export default function App() {
  const { classes } = useStyles();
  return (
      <div className="app min-h-screen text-blue-200 flex items-center flex-col p-20">
        <CssBaseline />
        <AppBar position="static" color="default" elevation={3} >
          <Container maxWidth="md" className={classes.noPad}>
            <img
              src={logo}
              alt="Micron Logo"
              width="300"
              align="center"
            />
          </Container>
        </AppBar>

        <MainPage />

        <Container maxWidth="md" component="footer" className={classes.footer}>
          <Grid container spacing={4} justifyContent="space-evenly" alignItems="center">
            <Typography component="h4" color="textPrimary">
              2998 SYENE ROAD | MADISON, WI 53713
            </Typography>
            <Divider />
            <Typography component="h4" color="textPrimary">
              Phone: <a className={classes.link} href="tel:+16082742099">(608) 274-2099</a> | <a className={classes.link} href={"mailto:gpalmer@micronbp.com"}>gpalmer@micronbp.com</a>
            </Typography>
          </Grid>
        </Container>
        {/* End footer */}
      </div>
  );
}
