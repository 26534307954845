import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import PhoneIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Map from "./Map";


const useStyles = makeStyles()((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  phone: {
    fontSize: '2em',
    color: 'black',
    textDecoration: 'none'
  },
  email: {
    fontSize: '1.4em',
    color: 'black',
    textDecoration: 'none'
  }
}));


export default function ContactPage() {
  const { classes } = useStyles();
  return (
    <div className="app min-h-screen text-blue-200 flex items-center flex-col p-20" id="contact">
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          For a free estimate or to schedule a service call, give us a ring or shoot us an email.
        </Typography>
      </Container>

      <Container maxWidth="md" component="main" style={{marginBottom: 24}}>
        <Grid
          container
          spacing={5}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <PhoneIcon style={{width: "100%", height: 70, padding: 12}} />
              <CardHeader
                title="Drop Us a Line"
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              />
              <CardContent>
                <Typography component="p" variant="subtitle1" align="center">

                  <a className={classes.phone} href="tel:+16082742099">(608) 274-2099</a>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <MailIcon style={{width: "100%", height: 70, padding: 12}} />
                <CardHeader
                  title="Email"
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <Typography component="p" variant="subtitle1" align="center">
                    <a className={classes.phone} href={"mailto:gpalmer@micronbp.com"}>gpalmer@micronbp.com</a>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
        </Grid>
      </Container>
      <Map />
    </div>
  );
}
