import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';


// const useStyles = makeStyles()((theme) => ({
//   cardHeader: {
//     backgroundColor:
//       theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
//   },
//   greenPaper: {
//     padding: theme.spacing(4),
//     backgroundColor: '#1e7b32',
//     color: 'white'
//   }
// }));

export default function TonerPage() {
  // const { classes } = useStyles();
  return (
    <Container maxWidth="md" id="toner">
      <Paper style={{padding: 20, backgroundColor: "#c8e2ff"}}>
        <Typography component="h3" variant="h4" align="left" color="textPrimary" gutterBottom>
          Local Toner Deliveries
        </Typography>
        <Typography component="p" variant="h6" align="left" color="textSecondary" gutterBottom>
          We sell almost every kind of toner cartridge, and will recycle your empty drums at no extra charge.
        </Typography>
        <Typography component="p" align="left" color="textPrimary" gutterBottom>
          Micron Business Products compatible laser toner cartridges cannot be compared to any re-manufactured toner cartridges you may have used recently. Our lab disassembles each cartridge by hand, cleans out all of the old toner and replaces all of the consumable parts. Each cartridge is then tested before carefully being packed for delivery or shipping. A replacement guarantee comes with every cartridge.
        </Typography>
      </Paper>
      {/* <br />
      <br /> */}
      {/* <Paper elevation={3} className={classes.greenPaper}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h3" align="left" color="white" gutterBottom>
              Go Green
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" color="#e5ddd1" component="p" gutterBottom>
              At Micron, we are committed to helping our clients comply with their environmental management standards. Although we produce many of our products in real time we order parts locally whenever possible and in bulk so as to minimize transportation impact. All of our cartridges are packaged in boxes made from recycled materials.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" color="#e5ddd1" component="p" gutterBottom>
              We reuse computer and printer parts as a means of reducing landfill refuse and are often able to rebuild old equipment for resale or donation.
            </Typography>
          </Grid>
        </Grid>
      </Paper> */}
    </Container>
  );
}
